.page-container {
    height: 100vh;
    background-color: #fff;
}

.page-title {
    background-color: #20444c;
    color: white;
    padding: 20px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.date-filter-container {
    display: flex;
    gap: 30px;
    margin-bottom: 20px;
    padding: 0 20px;
}



.date-picker-wrapper {
    display: flex;
    flex-direction: column;
    align-items: left;
    z-index: 100;
}

.date-picker-wrapper label {
    margin-bottom: 2px;
    color: #20444c;
    font-size: small;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
}



.react-datepicker__input-container input {
    width: 100%;
    padding: 10px;
    border: 2px solid #20444c;
    border-radius: 5px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}








/* @media (min-width: 600px) {
    .date-filter-container {
        flex-direction: row;
        justify-content: center;
        gap: 20px;
    }
} */

/* Media queries for table responsiveness */
@media (max-width: 768px) {
    .table-site-container {
        overflow-x: auto;
        /* Enable horizontal scrolling for smaller screens */
    }

    .page-title {
        font-size: 1.5rem;
        /* Adjust title size */
    }

    .filter-btn {
        padding: 10px;
        /* Adjust button padding */
    }
}


@media (max-width: 600px) {


    .page-title {
        height: 70px;
    }



    .page-title h1 {
        font-size: 1.5rem;
        /* Reduce header font size on very small screens */
    }



}