/* Global styles */


/* App container */
.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

}

/* Header styles */
.header {
    background-color: #20444c;
    color: white;
    padding: 20px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header h1 {
    margin: 0;
    font-size: 2rem;
}

/* Main content styles */
.main-content {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;

}

/* Add campaign section */
.add-campaign {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.add-campaign h2 {
    margin-bottom: 16px;
    color: #20444c;
}

.input-group {
    display: flex;
    flex-direction: row;
    /* Keep items in a row on larger screens */
    flex-wrap: wrap;
    /* Allow items to wrap on smaller screens */
    gap: 10px;
}

.input {
    flex: 1;
    /* Allow input to grow */
    min-width: 150px;
    /* Minimum width for input */
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}



.add-button {
    background-color: #20444c;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.disable-button {
    background-color: rgba(183, 178, 178, 0.404);
    color: rgba(128, 128, 128, 0.404);
    cursor: not-allowed;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 1rem;


}

.add-button:hover {
    background-color: #16343d;
}

/* Campaign list section */
.campaign-list {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

}

.campaign-list h2 {
    margin-bottom: 16px;
    color: #20444c;
}

.table-container {
    width: 100%;
    overflow-x: auto;
    max-height: 40vh;

}

.campaign-loader {
    display: flex;
    justify-content: center;
}

.campaign-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;

}

.table-head,
.table-data {
    padding: 12px;
    border-bottom: 1px solid #ddd;
}

.table-head {
    background-color: #20444c;
    color: white;
}

.table-row:hover {
    background-color: #f1f1f1;
}

.empty-message {
    text-align: center;
    color: #888;
}

/* Footer styles */
.footer {
    text-align: center;
    padding: 20px;
    background-color: #f1f1f1;
    color: #888;
    font-size: 0.9rem;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

/* Responsive design */
@media (max-width: 768px) {



    .table-head,
    .table-data {
        padding: 8px;
        /* Smaller padding on mobile */
        font-size: 0.9rem;
        /* Slightly smaller font size */
    }
}

@media (max-width: 600px) {
    .input-group {
        flex-direction: column;
        /* Stack input and button vertically */
        align-items: stretch;
        /* Ensure they take full width */
    }

    .header {
        height: 70px;
    }

    .input {
        min-width: 100%;
        /* Full width on smaller screens */
    }

    .add-button {
        width: 100%;
        /* Full width on smaller screens */
    }

    .header h1 {
        font-size: 1.5rem;
        /* Reduce header font size on very small screens */
    }

    .add-campaign h2 {
        font-size: 1.2rem;
        /* Smaller heading size */
    }

    .campaign-list h2 {
        font-size: 1.2rem;
        /* Smaller heading size */
    }

    .campaign-table {
        font-size: 0.8rem;
        /* Smaller font size for the table */
    }

    .table-head,
    .table-data {
        padding: 10px;
        /* Ensure padding is not too tight */
    }

    .input {
        min-width: 100%;
        /* Full width input */
    }
}

@media (max-width: 520px) {
    .input {
        padding: 8px;
        /* Adjust padding for smaller screens */
        font-size: 0.9rem;
        /* Adjust font size for smaller input */
    }

    .add-button {
        padding: 8px;
        /* Adjust button padding */
        font-size: 0.9rem;
        /* Adjust font size for button */
    }

    .table-head,
    .table-data {
        font-size: 0.75rem;
        /* Further reduce font size for very small screens */
    }
}