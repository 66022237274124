.campaign-page-container {
    height: 100vh;
    background-color: #fff;
}

.campaign-page-title {
    background-color: #20444c;
    color: white;
    padding: 20px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filter-container {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

@media (max-width: 600px) {

    .filter-container {
        gap: 10px;
        flex-direction: column;
    }
}





.loader {
    display: flex;
    justify-content: center;
    align-items: center;
}










/* @media (min-width: 600px) {
    .date-filter-container {
        flex-direction: row;
        justify-content: center;
        gap: 20px;
    }
} */

/* Media queries for table responsiveness */
@media (max-width: 768px) {
    .table-site-container {
        overflow-x: auto;
        /* Enable horizontal scrolling for smaller screens */
    }

    .campaign-page-title {
        font-size: 1.5rem;
        /* Adjust title size */
    }

    .filter-btn {
        padding: 10px;
        /* Adjust button padding */
    }
}


@media (max-width: 600px) {


    .campaign-page-title {
        height: 70px;
    }



    .campaign-page-title h1 {
        font-size: 1.5rem;
        /* Reduce header font size on very small screens */
    }



}