/* Container of the custom-dropdown */
.custom-dropdown {
    position: relative;
    width: 100%;
    max-width: 300px;
    /* Set a max width for responsiveness */
    /* Center the custom-dropdown */
    font-family: 'Arial', sans-serif;
    font-size: 16px;
}

/* custom-Dropdown header title (for description) */
.custom-dropdown-header-title {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 4px;
    color: #20444c;
    /* Primary color for header */
}

/* custom-Dropdown header */
.custom-dropdown-header {
    padding: 12px;
    background-color: #fff;
    border: 2px solid #20444c;
    /* Border with primary color */
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: border 0.3s ease, box-shadow 0.3s ease;
}

/* custom-Dropdown header on hover/focus */
.custom-dropdown-header:hover {
    border-color: #1b3a42;
    /* Slightly darker shade on hover */
    box-shadow: 0 4px 8px rgba(32, 68, 76, 0.2);
    /* Subtle shadow */
}

/* custom-Dropdown arrow icon */
.custom-dropdown-arrow {
    margin-left: 10px;
    color: #20444c;
    transition: transform 0.3s ease;
}

.custom-dropdown-arrow.open {
    transform: rotate(180deg);
    /* Rotate the arrow when open */
}

/* custom-Dropdown list */
.custom-dropdown-list {
    position: absolute;
    width: 100%;
    background-color: white;
    border: 1px solid #20444c;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(32, 68, 76, 0.1);
    margin-top: 5px;
    z-index: 1000;
    padding: 4px 0;
    max-height: 250px;
    overflow-y: auto;


}

/* custom-Dropdown items */
.custom-dropdown-item {
    padding: 10px 12px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

/* Hover and active states for custom-dropdown items */
.custom-dropdown-item:hover {
    background-color: #20444c;
    color: white;
}

.custom-dropdown-item:active {
    background-color: #1b3a42;
}

/* None option styling */
.custom-dropdown-item.none-option {
    font-style: italic;
    color: #888;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
    .custom-dropdown {
        max-width: 100%;
    }

    .custom-dropdown-list {

        max-height: 150px;


    }
}