.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
    /* Add padding for smaller screens */
}

.login-card {
    background-color: #fff;
    border-radius: 15px;
    padding: 40px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    animation: fadeIn 1.2s ease-in-out;
}

.login-container h2 {
    text-align: center;
    color: #20444c;
    margin-bottom: 25px;
    font-weight: 700;
}

.form-group {
    margin-bottom: 20px;
}

.login-container label {
    font-weight: 600;
    margin-bottom: 8px;
    display: inline-block;
    color: #495057;
}

.login-container input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ced4da;
    border-radius: 10px;
    font-size: 16px;
    background-color: #f7f7f7;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.login-container input:focus {
    outline: none;
    border-color: #20444c;
    box-shadow: 0 0 8px rgba(32, 68, 76, 0.2);
}

.login-container input::placeholder {
    color: #adb5bd;
}

.input-error {
    border-color: #e74c3c;
}

.error-text {
    color: #e74c3c;
    /* Red color for error messages */
    font-size: 14px;
    margin-top: 5px;
}

.btn-submit {
    width: 100%;
    background-color: #20444c;
    color: #fff;
    padding: 14px;
    border: none;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn-submit:hover {
    background-color: #18393d;
    transform: scale(1.05);
}

@media screen and (max-width: 768px) {
    .login-card {
        padding: 30px;
        margin: 20px;
    }

    .login-container h2 {
        font-size: 24px;
    }

    .login-container input {
        font-size: 14px;
    }

    .btn-submit {
        padding: 12px;
        font-size: 14px;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}